<template>
  <div>
    <div class="common-container">
      <v-form>
        <v-row
          class="mr-4 mt-4 d-inline-flex justify-space-between align-center"
          style="width: 100%"
        >
          <v-col cols="12" md="2"> </v-col>
          <v-col cols="12" md="2" class="d-flex"> </v-col>
        </v-row>
      </v-form>
      <h2 v-if="disputesTable">
        {{ $tc("disputes.title", disputesTable.length) }}
      </h2>
      <div v-if="disputesTable">
        <v-data-table
          :headers="headers"
          :items="disputesTable"
          :items-per-page="5"
          class="elevation-0"
          :item-class="kill_classes"
          :hide-default-footer="disputesTable.length < 5"
        >
          <template v-slot:[`item.id`]="props">
            <router-link
              :to="{
                name: 'dispute-detail',
                params: { id: props.item.id },
              }"
            >
              {{ props.item.id }}
            </router-link>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              text
              color="error"
              class="mx-3"
              small
              @click="confirmDeleteItem(item)"
            >
              <v-icon color="error">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <confirm-dialog ref="confirm"></confirm-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

export default {
  name: "disputes",
  components: { ConfirmDialog },
  async mounted() {
    await this.fetchLocation();
    await this.fetchDisputesTable({});
  },
  data() {
    return {
      selectedLocation: null,
      cycleValue: null,
      selectedStatus: null,
      isFilterKill: null,
      params: {},
      headers: [
        {
          text: i18n.t("disputes.id"),
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: i18n.t("disputes.status"), value: "status.status_description" },
        { text: i18n.t("disputes.date"), value: "date" },
        { text: i18n.t("disputes.created_by"), value: "created_by.email" },
        {
          text: i18n.t("disputes.assigned"),
          value: "assigned_to.email",
        },
        {
          text: i18n.t("disputes.location"),
          value: "location1.description",
        },
        {
          text: i18n.t("disputes.location"),
          value: "location2.description",
        },
        { text: i18n.tc("label.action", 2), value: "action" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchLocation: "dashboard/fetchLocation",
      fetchTransits: "dashboard/fetchTransits",
      fetchDisputesTable: "disputes/fetchDisputesTable",
      deleteDispute: "disputes/deleteDispute",
    }),
    kill_classes(item) {
      console.log("item.kill: ", item.kill);
      if (item.kill) {
        return "kill-class";
      }
    },
    handleChange() {
      this.fetchDisputesTable(this.params);
    },
    async confirmDeleteItem(item) {
      if (
        await this.$refs.confirm.open(
          `${i18n.t("label.delete")}`,
          `${i18n.t("user.delete-message", { item: item.id })}`,
          this.options
        )
      ) {
        this.deleteRecord(item.id);
      }
    },
    deleteRecord(id) {
      console.log(id);
      this.deleteDispute(id)
        .then(() => {
          this.fetchDisputesTable();
        })
        .catch((err) => console.log("Delete dispute error :", err));
    },
  },
  computed: {
    ...mapGetters({
      locations: "dashboard/getLocations",
      disputesTable: "disputes/getDisputesTable",
    }),
  },
};
</script>

<style></style>
